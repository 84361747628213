import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const RemoveCardModal = ({ open, onClose, onConfirm, cardName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Fjern Fakturakort</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Er du sikker på at du vil fjerne fakturakortet fra lommeboken din?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: 'lightgrey',
              color: 'black',
              '&:hover': {
                backgroundColor: 'grey',
              },
            }}
          >
            Avbryt
          </Button>
          <Button
            onClick={onConfirm}
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
            autoFocus
          >
            Bekreft
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveCardModal;
