import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CardList from '../components/CardList';
import Header from '../components/Header';
import AddCardModal from '../components/AddCardModal';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initDB, getAllCards, addCard as addCardToDB, deleteCard as deleteCardFromDB } from '../helpers/indexedDB';

const Home = () => {
  const [cards, setCards] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [db, setDb] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeDB = async () => {
      try {
        const database = await initDB();
        setDb(database);
        const storedCards = await getAllCards(database);
        setCards(storedCards);
      } catch (error) {
        console.error('Error initializing database:', error);
      }
    };

    initializeDB();
  }, []);

  const addCard = async (card) => {
    if (!db) {
      toast.error('Systemfeil.');
      return;
    }
    const exists = cards.some(c => c.prepaid_card_id === card.prepaid_card_id);
    if (exists) {
      toast.error('Fakturakortet du prøver å legge til, eksisterer allerede i lommeboken din.');
      return;
    }
    const newCards = [...cards, card];
    setCards(newCards);
    try {
      await addCardToDB(db, card);
      toast.success('Fakturakort lagt til vellykket.');
    } catch (error) {
      console.error('Error adding card to database:', error);
      toast.error('Klarte ikke å legge til fakturakortet.');
    }
  };

  const removeCard = async (card) => {
    if (!db) {
      toast.error('Systemfeil.');
      return;
    }
    const newCards = cards.filter(c => c.prepaid_card_id !== card.prepaid_card_id);
    setCards(newCards);
    try {
      await deleteCardFromDB(db, card.prepaid_card_id);
      toast.success('Fakturakort fjernet vellykket.');
    } catch (error) {
      console.error('Error removing card from database:', error);
      toast.error('Klarte ikke å fjerne fakturakortet.');
    }
  };

  const viewCard = (card) => {
    navigate(`/card/${card.prepaid_card_id}`);
  };

  const handleAddCard = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const prepaidCardId = window.location.pathname.split('/')[2];
    const organizationId = window.location.pathname.split('/')[1];
    const host = process.env.REACT_APP_BACKEND_SERVER_HOST;

    if (token && prepaidCardId && organizationId) {
      try {
        const response = await axios.get(`${host}/api/v2.0/wallet/${organizationId}/${prepaidCardId}/`, {
          params: {
            card_token: token
          }
        });

        const cardData = response.data;
        setCardData(cardData);
        setModalOpen(true);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error('Lenken du bruker for å legge til fakturakortet er ugyldig.');
          } else if (error.response.status === 404) {
            toast.error('Fakturakortet du prøver å legge til i lommeboken din eksisterer ikke i Betala-systemet.');
          } else {
            toast.error('Klarte ikke hente fakturakortdata. Vennligst prøv igjen senere.');
          }
        } else {
          toast.error('Klarte ikke hente fakturakortdata. Vennligst sjekk internettforbindelsen din.');
        }
      }
    }
  };

  const handleConfirm = () => {
    if (cardData) {
      addCard({
        prepaid_card_id: cardData.prepaid_card_id,
        name: cardData.name,
        amount: cardData.amount,
        ref_code: cardData.ref_code,
        organization_id: cardData.organization_id,
        created: cardData.created,
        company_name: cardData.company_name,
        company_logo_link: cardData.company_logo_link,
        category: cardData.category,
        customer_name: cardData.customer_name,
        customer_email: cardData.customer_email,
        customer_phone_number: cardData.customer_phone_number,
        email_message: cardData.email_message,
        invoiced: cardData.invoiced,
        card_token: cardData.card_token,
        expiry: cardData.expiry,
        revoked: cardData.revoked,
        external_reference: cardData.external_reference,
        amount_spent: cardData.amount_spent,
        nof_transactions: cardData.nof_transactions,
      });
      setModalOpen(false);
    }
  };

  useEffect(() => {
    handleAddCard();
  }, []);

  return (
    <div>
      <Header />
      <ToastContainer />
      {cards.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div>Ingen kort er lagt til i lommeboken din ennå</div>
        </div>
      ) : (
        <div style={{ marginTop: '80px' }}>
          <CardList cards={cards} onRemove={removeCard} onView={viewCard} />
        </div>
      )}
      <AddCardModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default Home;