import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import CardDetails from '../components/CardDetails';
import { initDB, getCardById } from '../helpers/indexedDB';

const CardDetailsPage = () => {
  const { cardId } = useParams();
  const navigate = useNavigate();
  const [card, setCard] = useState(null);

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const db = await initDB();
        const cardData = await getCardById(db, cardId);
        setCard(cardData);
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    fetchCardData();
  }, [cardId]);

  if (!card) {
    return <div>Fakturakort ikke funnet</div>;
  }

  return (
    <div>
      <Header />
      <CardDetails card={card} onBack={() => navigate('/')} />
    </div>
  );
};

export default CardDetailsPage;