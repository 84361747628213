import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HomePage from './pages/Home';
import CardDetailsPage from './pages/CardDetailsPage';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:organizationId/:cardId" element={<HomePage />} />
          <Route path="/card/:cardId" element={<CardDetailsPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
