import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Typography from '@mui/material/Typography';
import RemoveCardModal from './RemoveCardModal';

const CardList = ({ cards, onRemove, onView }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState(null);

  const getStatusColor = (card) => {
    if (card.revoked) {
      return '#f44336';
    } else if (card.invoiced) {
      return '#f3ff84';
    } else {
      return '#4caf50';
    }
  };

  const handleRemoveClick = (card) => {
    setSelectedCard(card);
    setOpen(true);
  };

  const handleConfirmRemove = () => {
    onRemove(selectedCard);
    setOpen(false);
    setSelectedCard(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCard(null);
  };

  return (
    <List sx={{ padding: 0 }}>
      {cards.map((card, index) => (
        <ListItem
          key={index}
          disablePadding
          sx={{
            marginBottom: '20px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              background: 'linear-gradient(90deg, rgba(75,126,255,1) 0%, rgba(53,110,255,1) 15%, rgba(27,90,250,1) 100%)',
              color: '#ffffff',
              padding: '20px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: 'calc(100% - 40px)',
              margin: '5px 30px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CreditCardIcon sx={{ fontSize: 40, marginRight: '20px', color: '#ffffff' }} />
              <div>
                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '5px' }}>
                  {card.name}
                </Typography>
                {card.company_name}<br /><br />
                <b>Saldo: {(card.amount - card.amount_spent) / 100} kr </b><br /><br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem', textTransform: 'uppercase' }}>
                  <span style={{ color: getStatusColor(card) }}>
                    {card.revoked ? 'Ugyldiggjort' : card.invoiced ? 'Fakturert' : 'Gyldig'}
                  </span>
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
              <Button
                onClick={() => onView(card)}
                sx={{ color: '#ffffff' }}
              >
                Vis
              </Button>
              <Button
                onClick={() => handleRemoveClick(card)}
                sx={{ color: '#ffffff', marginLeft: '10px' }}
              >
                Fjern
              </Button>
            </div>
          </Paper>
        </ListItem>
      ))}
      <RemoveCardModal
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirmRemove}
      />
    </List>
  );
};

export default CardList;
