import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import QRCode from 'react-qr-code';
import axios from 'axios';
import { initDB, updateCard as updateCardInDB } from '../helpers/indexedDB';

const CardDetails = ({ card, onBack }) => {
  const [updatedCard, setUpdatedCard] = useState(card);

  useEffect(() => {
    const initializeDB = async () => {
      const database = await initDB();
      fetchCardData(database);
    };

    const fetchCardData = async (db) => {
      const host = process.env.REACT_APP_BACKEND_SERVER_HOST;
      try {
        const response = await axios.get(
          `${host}/api/v2.0/wallet/${updatedCard.organization_id}/${updatedCard.prepaid_card_id}/`, {
            params: {
              card_token: updatedCard.card_token
            }
          }
        );
        const updatedCardData = response.data;
        const newCard = { ...updatedCard, ...updatedCardData };
        setUpdatedCard(newCard);
        await updateCardInDB(db, newCard);
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    initializeDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusColor = () => {
    if (updatedCard.revoked) {
      return 'red';
    } else if (updatedCard.invoiced) {
      return 'blue';
    } else {
      return 'green';
    }
  };

  const expiryDate = updatedCard.expiry ? updatedCard.expiry.split('T')[0] : '';

  return (
    <div style={{ margin: '50px auto', maxWidth: '600px', textAlign: 'center' }}>
      {updatedCard.company_logo_link && <img src={updatedCard.company_logo_link} alt="Company Logo" style={{ height: '100px', marginBottom: '5px' }} />}
      <h2 style={{ marginTop: '0px' }}>{updatedCard.name}</h2>
      <div style={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
        <p style={{ margin: 5, padding: 0 }}><strong>Bedrift : </strong>{updatedCard.company_name}</p>
        <p style={{ margin: 5, padding: 0 }}><strong>Kunde : </strong>{updatedCard.customer_name}</p>
        <p style={{ margin: 5, padding: 0 }}><strong>Beløpsgrense : </strong>{updatedCard.amount / 100} kr</p>
        <p style={{ margin: 5, padding: 0 }}><strong>Tilgjengelig beløp : </strong>{(updatedCard.amount - updatedCard.amount_spent) / 100} Kr</p>
      </div>
      {updatedCard.ref_code && <QRCode value={updatedCard.ref_code} style={{ margin: '10px auto' }} />}
      <h2 style={{ margin: '5px 0' }}>
        <p style={{ color: getStatusColor(), margin: 0 }}>{updatedCard.revoked ? 'Ugyldiggjort' : updatedCard.invoiced ? 'Fakturert' : 'Gyldig'}</p>
      </h2>
      <p style={{ marginTop: '10px' }}><strong>Utløpsdato:</strong> {expiryDate}</p>
      <Button style={{ marginTop: '10px' }} onClick={onBack} variant="contained">Tilbake til Lommebok</Button>
    </div>
  );
};

export default CardDetails;
