export const initDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('BetalaWalletDB', 1);
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore('cards', { keyPath: 'prepaid_card_id' });
      };
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        console.error('Error initializing DB:', event.target.errorCode);
        reject(event.target.errorCode);
      };
    });
  };
  
  export const getAllCards = (db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['cards'], 'readonly');
      const store = transaction.objectStore('cards');
      const request = store.getAll();
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        console.error('Error fetching cards:', event.target.errorCode);
        reject(event.target.errorCode);
      };
    });
  };
  
  export const getCardById = (db, cardId) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['cards'], 'readonly');
      const store = transaction.objectStore('cards');
      const request = store.get(Number(cardId));
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        console.error('Error fetching card by ID:', event.target.errorCode);
        reject(event.target.errorCode);
      };
    });
  };
  
  export const addCard = (db, card) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['cards'], 'readwrite');
      const store = transaction.objectStore('cards');
      const request = store.add(card);
  
      request.onsuccess = () => {
        resolve();
      };
  
      request.onerror = (event) => {
        console.error('Error adding card:', event.target.errorCode);
        reject(event.target.errorCode);
      };
    });
  };
  
  export const deleteCard = (db, cardId) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['cards'], 'readwrite');
      const store = transaction.objectStore('cards');
      const request = store.delete(cardId);
  
      request.onsuccess = () => {
        resolve();
      };
  
      request.onerror = (event) => {
        console.error('Error deleting card:', event.target.errorCode);
        reject(event.target.errorCode);
      };
    });
  };
  
  export const updateCard = (db, card) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['cards'], 'readwrite');
      const store = transaction.objectStore('cards');
      const request = store.put(card);
  
      request.onsuccess = () => {
        resolve();
      };
  
      request.onerror = (event) => {
        console.error('Error updating card:', event.target.errorCode);
        reject(event.target.errorCode);
      };
    });
  };
  