import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const Header = () => {
  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#f3ff84', color: 'black' }}>
      <Toolbar>
        <img src="/logo192.png" alt="App Logo" style={{ marginRight: '10px', height: '40px' }} />
        <div style={{ color: 'black', fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: 600 }}>
          Betala Wallet
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
